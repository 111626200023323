<template>
  <div v-if="$q.screen.width >= 1000" class="row wrap-reverse">
    <profile-info :class="{ 'q-mr-lg': $q.screen.width >= 1000 }" />

    <div class="column no-wrap width-fit-content-flex">
      <router-view></router-view>
    </div>
  </div>
  <div v-else class="q-mt-xs column full-height  justify-between">
    <div class="q-mb-lg">
      <q-btn
        @click="open"
        unelevated
        color="grey-4"
        class="full-width"
        text-color="dark"
        icon="expand_more"
        :label="$t('account.more_info')"
      />
    </div>
    <q-scroll-area
      :thumb-style="thumbStyle"
      :bar-style="barStyle"
      class="full-width"
      style="height: 90vh"
    >
      <div class="column no-wrap width-fit-content-flex">
        <router-view></router-view>
      </div>
    </q-scroll-area>

    <q-dialog v-model="dialog" position="bottom">
      <profile-info
        style="max-height: 75vh"
        :class="{ 'q-mr-lg': $q.screen.width >= 1000 }"
      />
    </q-dialog>
  </div>
</template>

<script>
import ProfileInfo from "@/modules/main/pages/account/components/profile-info";
import { ref } from "vue";

export default {
  name: "Edit",

  components: { ProfileInfo },

  emits: ["changeTab"],

  setup() {
    const dialog = ref(false);

    return {
      dialog,

      open() {
        dialog.value = true;
      },

      thumbStyle: {
        width: "0",
        right: "0",
        opacity: 0.75,
        borderRadius: "0",
        backgroundColor: "#027be3",
      },

      barStyle: {
        right: "0",
        width: "0",
        opacity: 0.2,
        borderRadius: "0",
        backgroundColor: "#027be3",
      },
    };
  },
};
</script>

<template>
  <template v-for="(item, i) in numOfElements" :key="i">
    <q-separator spaced inset="item" />
    <q-item>
      <q-item-section avatar>
        <q-skeleton type="QAvatar" />
      </q-item-section>
      <q-item-section>
        <q-item-label><q-skeleton type="rect" width="240px"/></q-item-label>
        <q-item-label caption class="text-justify" style="max-width: 200px">
          <q-skeleton type="rect" width="240px" />
        </q-item-label>
      </q-item-section>
      <q-item-section side>
        <q-item-label caption
          ><q-skeleton type="rect" width="120px"
        /></q-item-label>
      </q-item-section>
    </q-item>
  </template>
</template>

<script>
import { ref } from "vue";

export default {
  name: "listSkeleton",

  setup() {
    return {
      numOfElements: ref([1, 2, 3, 4, 5, 6, 7, 8]),
    };
  },
};
</script>

import i18n from "@/i18n/wrappers/i18n";

const t = i18n.global.t;

export const getPersonalInfosMenus = ({
  isPF,
  routeType,
  canVerifyDocs,
  hasExternalKey,
  isSlugReadOnly,
}) => [
  {
    redirect: { name: `${routeType}.contact-details` },
    icon: "email",
    title: t("menu.account.children.change-email"),
    desc: "",
    caption: t("menu.account.labels.acc_and_security"),
    titleColor: "text-black",
    condition: () => true,
  },
  {
    redirect: { name: `${routeType}.change-password` },
    icon: "lock",
    title: t("menu.account.children.change-password"),
    desc: "",
    caption: t("menu.account.labels.acc_and_security"),
    titleColor: "text-black",
    condition: () => true,
  },
  {
    redirect: { name: `${routeType}.personal-info` },
    icon: "face",
    title: t("menu.account.children.personal-info"),
    desc: "",
    caption: t("menu.account.labels.personal_info"),
    titleColor: "text-black",
    condition: () => true,
  },
  {
    redirect: { name: `${routeType}.my-documents` },
    icon: "description",
    title: t("menu.account.children.my-documents"),
    desc: t("menu.account.descriptions.my_docs"),
    caption: t("menu.account.labels.documents"),
    titleColor: "text-black",
    condition: () => canVerifyDocs,
  },
  {
    redirect: { name: `${routeType}.company-data` },
    icon: "domain",
    title: t("menu.account.children.company-data"),
    desc: t("menu.account.descriptions.company-data"),
    caption: t("menu.account.labels.personal_info"),
    titleColor: "text-black",
    condition: () => isPF,
  },
  {
    redirect: { name: `${routeType}.address` },
    icon: "streetview",
    title: t("menu.account.children.address"),
    desc: "",
    caption: t("menu.account.labels.personal_info"),
    titleColor: "text-black",
    condition: () => true,
  },
  {
    redirect: { name: `${routeType}.bank-account` },
    icon: "account_balance",
    title: t("menu.account.children.bank-account"),
    desc: "",
    caption: t("menu.account.labels.payments"),
    titleColor: "text-black",
    condition: () => hasExternalKey,
  },
  {
    redirect: { name: `${routeType}.social-account` },
    icon: "share",
    title: t("menu.account.children.social-account"),
    desc: "",
    caption: t("menu.account.labels.shared"),
    titleColor: "text-black",
    condition: () => true,
  },
  {
    redirect: { name: `${routeType}.change-space` },
    icon: "store",
    title: t("menu.account.children.change-space"),
    desc: t("menu.account.descriptions.change_my_space"),
    caption: t("menu.account.labels.store"),
    titleColor: "text-black",
    condition: () => isSlugReadOnly,
  },
  {
    redirect: { name: `${routeType}.disable-account` },
    icon: "public_off",
    title: t("menu.account.children.disable-account"),
    desc: t("menu.account.descriptions.disable_account"),
    caption: t("menu.account.labels.acc_and_security"),
    titleColor: "text-negative",
    condition: () => true,
  },
];

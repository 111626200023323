<template>
  <div class="default-shadow default-rounded bg-white">
    <div class="column items-center q-pa-sm">
      <div class="q-mt-lg column items-center">
        <avatar class="q-mb-sm" size="150px" />
        <upload-photo />
      </div>
      <div>
        <q-list bordered class="list" v-if="!in_request">
          <q-item-label header class="text-weight-medium">Ações</q-item-label>
          <template
            v-for="(item, i) in getPersonalInfosMenus(conditions)"
            :key="i"
          >
            <q-separator inset="item" v-if="item.condition()" />
            <q-item
              v-ripple
              clickable
              :class="{ 'bg-blue-1': item.redirect.name === $route.name }"
              :to="item.redirect"
              v-if="item.condition()"
            >
              <q-item-section avatar>
                <q-icon color="indigo-9" :name="item.icon" />
              </q-item-section>
              <q-item-section>
                <q-item-label :class="item.titleColor">{{
                  item.title
                }}</q-item-label>
                <q-item-label
                  caption
                  class="text-justify"
                  v-if="item.desc.length"
                  style="max-width: 200px"
                >
                  {{ item.desc }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-item-label caption>{{ item.caption }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-list>
        <q-list bordered class="list" v-else>
          <list-skeleton />
        </q-list>
      </div>
    </div>
  </div>
</template>

<script>
import UploadPhoto from "@/modules/main/pages/account/components/uploadPhoto";
import { useGetters, useActions } from "vuex-composition-helpers";
import listSkeleton from "./skeletons/listSkeleton.vue";
import Avatar from "@/modules/main/components/avatar";
import { getPersonalInfosMenus } from "./setup";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "ProfileInfo",
  components: { UploadPhoto, Avatar, listSkeleton },
  setup() {
    const route = useRoute(),
      routeType = ref(route.path.split("/")[1]),
      in_request = ref(false),
      conditions = ref({});

    const { params, slugReadyOnly, partner } = useGetters({
        params: "account/getParams",
        slugReadyOnly: "partner/getSlugIsReadyOnly",
        partner: "account/getPartnerAccount",
      }),
      { fetchParametersByKeys } = useActions({
        fetchParametersByKeys: "account/fetchParametersByKeys",
      });

    const getExternalKey = (obj) => obj?.payment_method?.value,
      verifyDocMenu = (obj) => {
        if (obj.allow_child_doc_upload) return obj.allow_child_doc_upload.value;
      };

    onMounted(async () => {
      in_request.value = true;
      await fetchParametersByKeys({
        keys: ["payment_method", "allow_child_doc_upload"],
      }).finally(() => {
        conditions.value = {
          routeType: routeType.value,
          hasExternalKey: getExternalKey(params.value) != "NONE",
          isSlugReadOnly: !slugReadyOnly.value,
          canVerifyDocs: verifyDocMenu(params.value),
          isPF: partner.value?.pf_pj === "PF",
        };
        in_request.value = false;
      });
    });

    return {
      conditions,
      in_request,
      getPersonalInfosMenus,
    };
  },
};
</script>

<style scoped>
.list {
  width: 470px;
}

@media screen and (max-width: 856px) {
  .list {
    width: 100%;
  }
}
</style>
